var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"o-poster-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] },"single-select":_vm.state.singleSelect,"show-select":"","single-expand":"","disable-sort":"","expanded":_vm.state.expanded},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"update:expanded":function($event){return _vm.$set(_vm.state, "expanded", $event)},"click:row":function (_, ref) {
	var expand = ref.expand;
	var isExpanded = ref.isExpanded;

	return expand(!isExpanded);
}},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.sender",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]}},{key:"item.sessionTopic",fn:function(ref){
var item = ref.item;
return [(item.sessionTopic && item.sessionTopic.name)?_c('span',[_vm._v(_vm._s(item.sessionTopic.name))]):_c('span',[_vm._v("---")])]}},{key:"item.authors",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.authors.length))]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.createdAt,'DD-MM-YYYY HH:mm')))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.showPosterDetails(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-details")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.details')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showPosterEdit(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])],1),_c('v-menu',{attrs:{"left":"","offset-x":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-list-status")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.status')))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.statusChange(item.id, 'active')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.active')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.statusChange(item.id, 'deleted')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.deleted')))])],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.state.selected),callback:function ($$v) {_vm.$set(_vm.state, "selected", $$v)},expression:"state.selected"}}),_c('m-poster-options',{attrs:{"posterList":_vm.state.selected},on:{"fetch":_vm.fetchData}}),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.state.posterDetails),callback:function ($$v) {_vm.$set(_vm.state, "posterDetails", $$v)},expression:"state.posterDetails"}},[_c('m-poster-details',{attrs:{"item":_vm.state.posterDetailsItem},on:{"close":function($event){_vm.state.posterDetails = false}}})],1),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.state.posterEdit),callback:function ($$v) {_vm.$set(_vm.state, "posterEdit", $$v)},expression:"state.posterEdit"}},[_c('m-poster-edit',{attrs:{"item":_vm.state.posterEditItem},on:{"close":function($event){_vm.state.posterEdit = false}}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }