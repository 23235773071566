























































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MPosterOptions: () =>
      import("@/components/molecules/posterSession/m-poster-options.vue"),
    MPosterDetails: () =>
      import("@/components/molecules/posterSession/m-poster-details.vue"),
    MPosterEdit: () =>
      import("@/components/molecules/posterSession/m-poster-edit.vue"),
  },
  props: {
    status: {
      type: String,
      required: false,
      default: "active",
    },
    posterNumber: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    sender: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, { root }) {
    const state = reactive({
      headers: [
        {
          value: "order",
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
        },
        {
          value: "index",
          text: root.$tc("panel.event.poster.list.posterNumber"),
          width: 1,
        },
        { value: "title", text: root.$tc("layout.misc.title") },
        {
          value: "sender",
          text: root.$tc("layout.misc.sender"),
          align: "right",
        },
        {
          value: "sessionTopic",
          text: root.$tc("layout.misc.topic"),
          align: "right",
        },
        {
          value: "authors",
          text: root.$tc("layout.misc.authorsCount"),
          width: 1,
          align: "right",
        },
        {
          value: "createdAt",
          text: root.$tc("layout.misc.sendDate"),
          align: "right",
          width: "150px",
        },

        {
          value: "actions",
          text: "",
          align: "right",
          width: 1,
        },
      ],
      selected: [],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      success: false,
      error: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      posterNumber: "",
      title: "",
      sender: "",
      searchTimeout: undefined as any,
      posterDetails: false,
      posterDetailsItem: {},
      posterEdit: false,
      posterEditItem: {},
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { page, itemsPerPage } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/poster`, {
          params: {
            page,
            itemsPerPage,
            status: props.status,
            index: state.posterNumber || undefined,
            title: state.title || undefined,
            sender: state.sender || undefined,
          },
        })
        .then(({ data: { posters, total } }) => {
          state.empty = false;
          state.items = posters;
          state.total = total;
          state.selected = [];
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const statusChange = (id: string, status: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        postersIds: [id],
        status,
      };

      state.loading = true;

      axiosInstance
        .put("poster/bulk", data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    watch(() => state.options, fetchData, { deep: true });
    watch(() => props.status, fetchData);
    watch(
      () => props.posterNumber,
      (newSearch) => {
        state.posterNumber = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );
    watch(
      () => props.title,
      (newSearch) => {
        state.title = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );
    watch(
      () => props.sender,
      (newSearch) => {
        state.sender = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    const showPosterDetails = (item: any) => {
      state.posterDetailsItem = Object.assign({}, item);
      state.posterDetails = true;
    };

    const showPosterEdit = (item: any) => {
      state.posterEditItem = Object.assign({}, item);
      state.posterEdit = true;
    };

    return {
      state,
      fetchData,
      statusChange,
      showPosterDetails,
      showPosterEdit,
    };
  },
});
